*{
  box-sizing: border-box;
}
html, body{
  margin: 0;
  padding: 0;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::placeholder{
  font-size: 1em;

}
header{
  height: auto;
  background-color: #000;
  color: #fff;
}
nav{
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
}
nav span img{
  width: 4rem;
}
nav div i{
  color: #fff;
  cursor: pointer;
}
nav ul{
  width: 40vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav ul li{
  list-style: none;
}
nav ul li a{
  text-decoration: none;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
}
nav ul li button{
  width: auto;
  border: 0;
  border-radius: 5px;
  height: 2.5rem;
  cursor: pointer;
}
h3{
  font-family: 'Montserrat', sans-serif;
}
.logo{
  display: flex;
  width: 10.5vw;
  flex-direction: row;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  justify-content: space-between;
}
.header-div{
  text-align: center;
  height: 30rem;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}
.header-div h1{
  font-family: 'Montserrat', sans-serif;
  font-size: 5rem;
}
.header-div h1 span{
  background: -webkit-linear-gradient(315deg, rgb(255,223,0) 0%, rgb(0,0,128) 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header-div h1 span::after{
  background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/664131/underline.svg');
  background-repeat: no-repeat;
}
.header-div p{
  font-family: 'Montserrat', sans-serif;

}
.header-div button{
  width: 18rem;
  height: 3rem;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;

}
.row{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.column{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
main{
  padding: 2.5rem;
}
main h2, section h2, main h5{
  text-align: center;
  font-family: 'Raleway', sans-serif;
}
.card, .benefits{
  width: 100%;
  height: auto;
  padding-bottom: 1em;
}
.card button{
  cursor: pointer;
  border: 0;
  border-radius: 5px;
  height: 2.5rem;
  color: #fff;

}
.card-details button{
  background-color: rgb(0,0,128);
  font-weight: lighter;
}
.card-description button{
  background: rgb(255,223,0);
  color: rgb(0,0,128);
  font-size: 1rem;
  font-weight: lighter;
  padding: 0.5rem;

}
.card-cover{
  width: 40rem;
  height: auto;
  margin-top: 1.5em;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding-bottom: 2rem;
}
.card-details{
  width: 16rem;
  height: 100%;
  /*border-right: 1px solid #ddd;*/
  padding-left: 1.5rem;
  padding-bottom: 1.5rem;
}
.card-details h2, .card-details h3, .card-details ul{
  font-family: 'Montserrat', sans-serif;
}
.card-details ul li{
  list-style: none;
}
.card-description{
  width: 24rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;

}
.card-description img{
  width: 24rem;
  height: 16rem;
}
.card-description div{
  height: 13.5rem;
  /**background-color: #eee;"🤯"**/
}
section{
  margin-top: 6rem;
}
.benefits h3{
  color: rgb(0,0,128);
}
.benefits div{
  width: 40rem;
  height: 15rem;
  margin-top: 2.5rem;
  border-top: 5px solid #eee; /*rgb(255,223,0);*/
  border-radius: 5px;
  padding: 1rem;
  font-family: 'Montserrat', sans-serif;

}
.benefits div:first-child, .benefits div:last-child{
  border-top: 5px solid #eee; /*rgb(0,0,128);*/
}
.benefits div span{
  font-family: 'Montserrat', sans-serif;
  justify-content: space-between;

}
.benefits div span img{
  width: 3.5rem;
}
.how{
  display: grid;
  margin-top: 2.5rem;
  padding: 2rem;
  height: auto;
  background-color: #111;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
}
.how h4{
  font-size: 2rem;
  background: -webkit-linear-gradient(315deg, rgb(255,223,0) 0%, rgb(0,0,128) 74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.how p{
  font-weight: lighter;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;

}
.how button{
  height: 2.5rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  color: #fff;
  background-color: #000;
  cursor: pointer;
}
.how a{
  margin: 4rem auto;
}
.how1, .how2, .how3, .how4{
  height: 13rem;
  justify-content: center;
  margin-top: 2rem;
}
.how2{
  justify-content: flex-start;
}
.how3{
  justify-content: flex-end;
}

.how11, .how12, .how13, .how14{
  height: 13rem;
  width: 30rem;
  background-color: #222;
  border-radius: 10px;
}
.how123{
  flex-direction: column;
  height: 13rem;
  width: 9rem;
  padding: 0.25rem;
  padding-bottom: 2rem;
  
}
.how124{
  height: 13rem;
  width: 16rem;
  align-items: center;
}
.testimonials{
  display: grid;
  height: auto;
  background-color: #222;
  color: #fff;
  padding-top: 4rem;
  padding-bottom: 8rem;
  font-family: 'Montserrat', sans-serif;
}
.testimony{
  width: 85vw;
  height: auto;
  justify-content: flex-end;
}
.testimony-cover{
  width: 100vw;
}
.test{
  width: 62rem;
  height: 26rem;
  background-color: #000;
  border-radius: 10px;
}
.carousel{
  width: 15vw;
  height: 15rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.carousel div{
  background-color: rgb(65,105,225);
  width: 1rem;
  height: 1rem;
  margin: 1rem;
  border-radius: 50%;
  cursor: pointer;
}
.services{
  height: auto;
  display: grid;
  padding-bottom: 5rem;
  padding-top: 2.5rem;
  font-family: 'Montserrat', sans-serif;
}
.services img{
  width: 5rem;
}
.works{
  justify-content: space-evenly;
}
.works h3{
  color: #fff;
}
.media{
  flex-direction: column;
}
.media a{
  text-decoration: none;
  color: #fff;
}
.media-pt{
  width: 20rem;
  margin-top: 1rem;
  justify-content: space-evenly;
}
.services-cover{
  flex-direction: column;
  height: auto;
  width: 27rem;
  background-color: #000;
  margin-top: 2rem;
  border-radius: 5px;
  padding: 2rem;
  color: #fff;
}
.contact{
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  padding-bottom: 5rem;
}
.contact a{
  color: #34B7F1;
}
.subscribe{
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  height: auto;
  padding-bottom: 2.5rem;
}
.subscribe form input{
  width: 20rem;
  height: 2.5rem;
  border: 0;
  background-color: #ccc;
  padding-left: 0.5rem;
}
.subscribe form button{
  width: 9rem;
  height: 2.5rem;
  border: 0;
  cursor: pointer;
  border-radius: 2px;
  color: #fff;
  font-size: 1.5;
  background-color: rgb(0,0,128);
}

footer{
  padding: 1rem;
  height: auto;
  background-color: #000;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
}
.payment-form{
  width:  40rem;
  height: 35rem;
  margin:  20px auto;
  background-color: black;
  color:  white;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  border-radius: 8px;
  
}
.payment-form2{
  margin: auto;
}
.payment-form2 input{
  width: 25rem;
  height: 2.5rem;
  margin-top: 10px;
  margin-bottom: 10px;

}
.payment-form2 button{
  width: 25rem;
  height: 2.5rem;
  font-size: 17px;
  cursor: pointer;
  background-color: green;
  border: 0;
  border-radius: 5px;
}
.ebook{
  width: 100%;
  height: auto;
  font-family: 'Montserrat', sans-serif;
  
}
.ebook-adj{
  width: 50%;
  height: auto;
  margin:auto;
  font-family: 'Montserrat', sans-serif;
  
}
.ebook div, .ebook-adj div{
  width: 15rem;
  height: auto;
  margin-bottom: 1rem;
  background: #f5f5f5;
}
.ebook div img, .ebook-adj div img{
  width: 100%;
  height: 20rem;
}
@media(min-width: 1281px){
  nav div{
    display: none;
  }
}
@media(max-width: 1280px){
  nav ul{
    display: none;
  }
.menu-cover{
  width:  100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 1;
  font-family: 'Montserrat', sans-serif;

}
.menu-cover ul li{
  color: #000;
  margin-top: 5rem;
  list-style: none;
}
.menu-cover ul li button{
  border:  0;
  border-radius: 5px;
  background-color: #000;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  width: 150px;
  height:  35px;

}
}
@media(max-width: 1024px){

  .testimony{
    padding-left: 5rem;
  }
}
@media(max-width: 550px){
  .header-div button{
    height: 50px;
  }
}
@media(max-width: 500px){
  .subscribe form button{
    margin-top: 0.7rem;
  }
  .header-div h1{
    font-size: 3rem;
  }
  .how11, .how12, .how13, .how14,.how1, .how2, .how3, .how4{
    height: auto;
    width: 100%;
  }
  .services-cover{
    width: 95%;
  }
  .header-div p{
    padding: 1rem;
  }
  .card-cover{
    width: 99%;
  }
  .card-description img{
    width: 100%;
  }
  .card-description div{
    padding-left:0.5rem ;
    display: grid;
  }
  .card-description button{
    margin: auto;
  }
  .card-cover{
    padding-bottom: 1rem;
  }
}
@media(max-width: 450px){
  .card-description{
    height: 32rem;
  }
  .benefits div{
    margin-bottom: 1.3rem;
  }
  h2{
    font-size: 1.5rem;
  }
  .contact{
    padding: 1rem;
  }
  .subscribe h3{
    font-size: 1rem;
  }
}
@media(max-width: 430px){
  h2{
    font-size: 1.2rem;
  }

}
@media(max-width: 400px){
  .benefits div:first-child{
    margin-bottom: 5rem;
  }
  .subscribe{
    padding: 0.5rem;
  }
}
@media(max-width: 370px){
  .benefits div:first-child{
    margin-bottom: 5rem;
  }
}
@media(max-width: 330px){
  .header-div h1{
    font-size: 2.8rem;
  }
  .header-div p{
    font-size: 1rem;
  }
}
.about_tutor{
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
}

.word{
  margin-top: 100px;
  margin-left: 50px;
}

@media only screen and (max-width: 750px){
.about_tutor{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
}
}
.word_tutor Link{
  color:black; 
  margin: 5px;  
  
}
.main_curr{
  padding-top:10px;
  width:100%;
  height:auto;
}
.box_curr{
  width:17rem;
  height:25rem;
  box-shadow:0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius:10px;
  height: auto;
}
.cover_curr{
  width:85%;
  height:auto;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  margin:auto;
  flex-wrap:wrap;
}
.box_curr h5{
  text-align:center;
}
.curriculum{
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
}
.curriculum h4{
  text-align: center;
}
.stats{
  font-family: 'Montserrat', sans-serif;
}
.accordion{
  width: 80%;
  font-family: 'Montserrat', sans-serif;
  display: grid;
  margin:  auto;
}
.accordion h3, .accordion span{
  text-align: center;
}
.accordion span{
  padding-bottom: 20px;
}
.contact-form{
  background-color: #000;
  height: auto;
  padding-top: 25px;
  font-family: 'Montserrat', sans-serif;
}
.contact-form input{
  width: 25rem;
  height: 2rem;
  border-radius: 5px;
  border: 0;
  background-color: #ccc;
  font-family: 'Montserrat', sans-serif;
}
.contact-form textarea{
  width: 30rem;
  height: 7.5rem;
  border-radius: 5px;
  resize: none;
  background-color: #ccc;
  font-family: 'Montserrat', sans-serif;
}
.contact-form button{
  width: 5rem;
  height: 2.5rem;
  border: 0;
  border-radius: 5px;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}
@media(max-width: 414px){
    .payment-form{
    width: 80%;
  }
  .curriculum{
    display: grid;
    padding-left: 2rem;
  }
  .box_curr{
    margin-top: 15px;
  }
  .payment-form2{
    width: 90%;
  }
  .payment-form2 input{
    width: 100%;
  }
  .payment-form2 button{
    width: 100%;
  }
  .contact-form{
    padding-left: 10px;
    padding-right: 10px;
  }
  .contact-form input, .contact-form textarea{
    width: 90%;
  }
  .contact-form input, .contact-form button, .contact-form textarea{
    margin-top: 5px;
  }
  .card-cover{
    height: auto;
  }
  .card-description h1{
    font-size: 1.5rem;
  }
  .card-description button{
    margin:  auto;
  }
}
@media(max-width: 1024px){
  .footer-form{
    flex-direction: column;
  }
  .contact-form input, .contact-form button, .contact-form textarea{
    margin-top: 5px;
  }
  .contact-form{
    margin-top: 100px;
  }
}
@media(max-width: 280px){
  .about_tutor img{
    width: 40vw;
  }
  .header-div h1{
    font-size: 2rem;
  }
  .header-div button{
    width: 10rem;
  }
  .card-description img{
    width: 90%;
  }
  .card-details button{
    margin-top: 5px;
  }
  .card-description h1{
    font-size: 1.2rem;
  }
  .benefits div{
  height: auto;
  margin-top: 5px;
  }
  .how{
    padding-right: 20px;
  }
  .subscribe form input{
    width: 14rem;
  }
  .media p{
    font-size: 0.8rem;
  }
  .how{
    padding-left: 15px;
  }
  .how124{
    padding: 5px;
  }
  .testimonials{
    display: none;
  }
}
.cohort{
  font-family: 'Montserrat', sans-serif;
}
.tees{
  font-family: 'Montserrat', sans-serif;
}
@media(max-width: 1000px){
  .tees img{
    width: 80%;
  }

}
.acc{
  font-family: 'Montserrat', sans-serif;
}
.about_tutor img{

  padding-top: 20px;
}
@media(max-width: 8000px){
  .about_tutor img{
    width: 20rem;
  }
}
@media(min-width: 1000px){
  .about_tutor img{
    width: 40rem;
  height: 20rem;
  padding-top: 20px;
}
  
}







